import React, { useContext } from 'react'
import { StateStore } from '../../containers/StoreProvider'
import { MenuContainer, MenuList } from '../layout'
import { MenuLink } from '../MenuLink'
import { OfflineIconContainer, OfflineMenuIcon } from '../OfflineMode'

export const NavigationMenu: React.FunctionComponent = () => {
  const { state } = useContext(StateStore)
  return (
    <>
      <OfflineIconContainer>
        {state.offlineMode && <OfflineMenuIcon offline={state.offlineMode} />}
      </OfflineIconContainer>
      <MenuContainer>
        <MenuList>
          <MenuLink to="/towing-jobs" label="Työt" category="orders" exact count={state.openJobsCount} />
          <MenuLink to="/search" label="Haku" category="search" />
          <MenuLink to="/shifts" label="Vuorot" category="shifts" />
          <MenuLink to="/settings" label="Asetukset" category="settings" />
        </MenuList>
      </MenuContainer>
    </>
  )
}
