import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { ACS } from '../../../../../../common/AccessControl'
import { towingServiceTypeOptions } from '../../../../../../common/options'
import {
  TowingRecordJobStatus,
  TowingRecordRouteType,
  TowingRecordServiceType,
  TowingRecordType,
  TypeIdentifier,
} from '../../../../../../common/types'
import { Maybe } from '../../../../../../common/types'
import { getBatteryServiceBattery } from '../../../../../../components/ALSubForm/options'
import { Button } from '../../../../../../components/Button'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import { setErrorNotification, setSuccessNotification } from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../../../../components/router'
import { FinishRouteAction } from '../../../../../../components/SosSubForm/FinishRoute/types'
import { arrivedAtDestinationEditableJobStatuses } from '../../../../../../components/SosSubForm/FinishRouteForm/ArrivalForm'
import { finishedAtDestinationEditableJobStatuses } from '../../../../../../components/SosSubForm/FinishRouteForm/FinishedForm'
import {
  UPDATE_ARRIVED_AT_DESTINATION_MUTATION,
  UPDATE_FINISHED_AT_DESTINATION_MUTATION,
} from '../../../../../../components/SosSubForm/mutation/mutation'
import {
  updateArrivedAtDestinationToMutationVariables,
  updateFinishedAtDestinationToMutationVariables,
} from '../../../../../../components/SosSubForm/mutation/toMutationVariables'
import {
  UpdateArrivedAtDestinationResponse,
  UpdateFinishedAtDestinationResponse,
} from '../../../../../../components/SosSubForm/mutation/types'
import { translateInputOptions } from '../../../../../../util/translateInputOptions'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType, TowingRecord } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData } from '../../types'
import { getRouteDateTimeByType, validateRouteTimes } from '../../validateRouteTimes'
import { ButtonContainer, RouteForm } from '../styled'
import { FinishRoute, ShowAddressOptions } from './FinishRoute'
import { getInitialValues } from './getInitialValues'
import { EDIT_FINISH_ROUTE_MUTATION, EditFinishRouteResponse } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { EditFinishRouteFormValues } from './types'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecord
  routeDone: boolean
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  routeDone,
  setTowingRecord,
}) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { currentUser, items, itemGroups },
  } = useContext(StateStore)
  const accessControl = new ACS(currentUser).setTowingRecord(towingRecord)
  const [done, setDone] = useState<boolean>(() => {
    if (towingRecord.type === TowingRecordType.sos) {
      return accessControl.canTowingRecord('editFinishedAtDestination')
    }
    return routeDone
  })
  const [action, setAction] = useState<FinishRouteAction>(() => {
    if (towingRecord.type === TowingRecordType.sos) {
      if (towingRecord.jobStatus === TowingRecordJobStatus.finished_at_breakdown_location) {
        return 'edit-arrived'
      }

      if (towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_destination) {
        return 'edit-finished'
      }
    }
    return 'default'
  })

  const [editTowingRecord, { loading: editTowingRecordLoading }] = useMutation<EditFinishRouteResponse>(
    EDIT_FINISH_ROUTE_MUTATION,
    {
      onCompleted(test) {
        const { editFinishRoute } = test
        if (editFinishRoute.__typename === 'EditTowingRecordSuccess') {
          // historyPush(`/towing-record/${towingRecord.id}`)
          setSuccessNotification(
            formatMessage(routeMessages.route_edit_notification_success_title),
            formatMessage(routeMessages.route_edit_notification_success_message)
          )
          if (!isSos) {
            if (setTowingRecord) {
              setTowingRecord(editFinishRoute.towingRecord, 'view-towing-record')
              return
            }
            historyPush(`/towing-record/${towingRecord.id}`)
          }
        } else {
          setErrorNotifications({ data: editFinishRoute })
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const [updateArrivedAtDestination, { loading: updateArrivedAtDestinationLoading }] = useMutation<
    UpdateArrivedAtDestinationResponse
  >(UPDATE_ARRIVED_AT_DESTINATION_MUTATION, {
    onCompleted(response) {
      const { updateArrivedAtDestination } = response
      if (updateArrivedAtDestination.__typename === 'UpdateArrivedAtDestinationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateArrivedAtDestination.towingRecord)
        }
        if (updateArrivedAtDestination.towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_destination) {
          setAction('edit-finished')
        } else {
          setAction('default')
        }
      } else {
        setErrorNotifications({ data: updateArrivedAtDestination })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const [updateFinishedAtDestination, { loading: updateFinishedAtDestinationLoading }] = useMutation<
    UpdateFinishedAtDestinationResponse
  >(UPDATE_FINISHED_AT_DESTINATION_MUTATION, {
    onCompleted({ updateFinishedAtDestination }) {
      if (updateFinishedAtDestination.__typename === 'UpdateFinishedAtDestinationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateFinishedAtDestination.towingRecord, 'view-towing-record')
        }
        // historyPush(`/towing-record/${towingRecord.id}`)
      } else {
        setErrorNotifications({ data: updateFinishedAtDestination })
      }
    },
  })
  const deliveredBattery =
    towingRecord.automobileAndTouringClubFinland &&
    towingRecord.automobileAndTouringClubFinland.batteryService &&
    towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery
      ? towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery
      : null

  const batteryOption = getBatteryServiceBattery(itemGroups, items, deliveredBattery)
  const initialValues = getInitialValues(towingRecord, batteryOption)

  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const isSos = towingRecord.type === TowingRecordType.sos
  const saveLabel = isSos ? (done ? 'Tallenna valmis' : 'Tallenna saapunut') : 'Tallenna kohteessa'

  const translatedServiceTypeOptions = translateInputOptions(towingServiceTypeOptions, formatMessage)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function submit(values: EditFinishRouteFormValues) {
    try {
      const routes = [
        getRouteDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
        getRouteDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
      ]
      if (towingRecord.type === TowingRecordType.sos) {
        routes.push(getRouteDateTimeByType(TowingRecordRouteType.finish_breakdown_location, towingRecord.routes))
        if (done) {
          routes.push(getRouteDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes))
          if (values.sosServiceOrder && values.sosServiceOrder.completedAtDestination) {
            validateRouteTimes(
              {
                date: values.sosServiceOrder.completedAtDestination.date as Date,
                time: values.sosServiceOrder.completedAtDestination.time,
                type: TowingRecordRouteType.finish_destination,
              },
              routes
            )
          }
        } else {
          validateRouteTimes(
            { date: values.finishRoute.date, time: values.finishRoute.time, type: values.finishRoute.type },
            routes
          )
        }
      } else {
        validateRouteTimes(
          { date: values.finishRoute.date, time: values.finishRoute.time, type: values.finishRoute.type },
          routes
        )
      }
    } catch (e) {
      return setErrorNotification('Virhe', e.message)
    }

    if (isSos) {
      if (!done) {
        if (!accessControl.canTowingRecord('editArrivedAtDestination')) {
          return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
        }

        const {
          finishRoute: { coords },
        } = values

        if (!coords.lat || !coords.long) {
          return setErrorNotification(
            'Virhe',
            'Määränpään koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
          )
        }

        await editTowingRecord({ variables: toMutationVariables(values) })
        await updateArrivedAtDestination({
          variables: updateArrivedAtDestinationToMutationVariables(values),
        })
      } else {
        if (!accessControl.canTowingRecord('editFinishedAtDestination')) {
          return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
        }
        await updateFinishedAtDestination({
          variables: updateFinishedAtDestinationToMutationVariables(values),
        })
      }
    } else {
      await editTowingRecord({ variables: toMutationVariables(values) })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values: EditFinishRouteFormValues) => {
        submit(values)
      }}
      render={({ setFieldValue, values }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            setValue(`finishRoute.address`, locationData.address)
            setValue(`finishRoute.city`, locationData.city)
            setValue(`finishRoute.zipcode`, locationData.zipcode)
            setValue(`finishRoute.coords.lat`, locationData.coords.lat)
            setValue(`finishRoute.coords.long`, locationData.coords.long)
            if (locationData.description) {
              setValue(`finishRoute.description`, locationData.description)
            }
          }
        }

        const updateRouteDate = () => {
          setValue(`finishRoute.date`, new Date())
        }

        const setAutoliittoRepairShopField = (value: string | null) => {
          setValue('automobileAndTouringClubFinland.repairShopId', value)
        }

        const showAddresInputAs = () => {
          if (initialValues.sosServiceOrder) {
            return ShowAddressOptions.SOS_REPAIR_SHOP
          }

          if (!initialValues.automobileAndTouringClubFinland) {
            return ShowAddressOptions.ADDRESS
          }

          if (initialValues.serviceType !== TowingRecordServiceType.TOWING) {
            return ShowAddressOptions.DISABLED
          }

          if (initialValues.automobileAndTouringClubFinland.repairShopId) {
            return ShowAddressOptions.REPAIR_SHOP
          }
          return ShowAddressOptions.ADDRESS
        }

        const setGeolocatedField = (geolocated: boolean) => {
          setValue('finishRoute.geolocated', geolocated)
        }

        const clear = () => {
          setFieldValue('finishRoute.address', '')
          setFieldValue('finishRoute.city', '')
          setFieldValue('finishRoute.time', '')
          setFieldValue('finishRoute.date', null)
          setFieldValue('finishRoute.coords.lat', null)
          setFieldValue('finishRoute.coords.long', null)
          setFieldValue('finishRoute.geolocated', false)
        }

        const reportPosition = (location: Geolocation, name: string) => {
          console.log('reportPosition', location)

          setFieldValue(`${name}.address`, location.address)
          setFieldValue(`${name}.city`, location.city)
          setFieldValue(`${name}.coords.lat`, location.coords.lat)
          setFieldValue(`${name}.coords.long`, location.coords.long)
        }

        const setRouteAction = (action: FinishRouteAction) => {
          console.log('setRouteAction', action)
          setAction(action)
          if (action === 'edit-arrived') {
            setDone(false)
          } else {
            setDone(true)
          }
        }

        return (
          <RouteForm>
            <FinishRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              isAutoliitto={isAutoliitto}
              isSos={isSos}
              setRouteLocation={setRouteLocation}
              updateRouteDate={() => updateRouteDate}
              repairShopId={
                towingRecord.automobileAndTouringClubFinland
                  ? towingRecord.automobileAndTouringClubFinland.repairShopId
                  : null
              }
              showAddresInputAs={showAddresInputAs()}
              setAutoliittoRepairShopField={setAutoliittoRepairShopField}
              translatedServiceTypeOptions={translatedServiceTypeOptions}
              serviceChannel={
                towingRecord.automobileAndTouringClubFinland
                  ? towingRecord.automobileAndTouringClubFinland.serviceChannel
                  : null
              }
              clear={clear}
              setGeolocatedField={setGeolocatedField}
              initialValues={initialValues}
              setLoading={setIsLoading}
              formatMessage={formatMessage}
              routeDone={done}
              reportPosition={reportPosition}
              setValue={setValue}
              getValue={getValue}
              action={action}
              setAction={setRouteAction}
              jobStatus={towingRecord.jobStatus}
              stationId={towingRecord.stationId}
            />

            <ButtonContainer floatAtBottom>
              <Loading
                loading={
                  editTowingRecordLoading ||
                  isLoading ||
                  updateArrivedAtDestinationLoading ||
                  updateFinishedAtDestinationLoading
                }
              />
              {!isSos && (
                <Button
                  category="save"
                  size="l"
                  label={saveLabel}
                  mobileLabel="Tallenna"
                  submit
                  maxWidth="100%"
                ></Button>
              )}
              {isSos &&
                action === 'edit-arrived' &&
                !arrivedAtDestinationEditableJobStatuses.includes(towingRecord.jobStatus) && (
                  <Button
                    category="save"
                    size="l"
                    label={saveLabel}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}
              {isSos &&
                action === 'edit-finished' &&
                !finishedAtDestinationEditableJobStatuses.includes(towingRecord.jobStatus) && (
                  <Button
                    category="save"
                    size="l"
                    label={saveLabel}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}

export const FinishRoutePage = injectIntl(RoutesIntl)
