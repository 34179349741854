import { decode } from 'jsonwebtoken'

import { Maybe } from '../common/types'
import { Item, ItemGroup, Preferences, Settings } from '../containers/Parameters/types'
import { SosArcCodesFilters } from '../functions/graphqlApi/types/sosServiceOrder'
import { BackendNotification } from '../util/notifications/types'

const STORED_AUTHTOKEN_KEY = 'authorization'
const STORED_AUTHTOKEN_EXP = 'expiration'
const STORED_ITEMGROUPS_KEY = 'item_groups'
const STORED_ITEMS_KEY = 'items'
const STORED_SETTINGS_KEY = 'settings'
const STORED_CUSTOMER_ID_KEY = 'user_id'
const STORED_COMPANY_ID_KEY = 'company_id'
const STORED_SERVICEWORKER_ENDPOINT = 'serviceworker_endpoint'
const STORED_STATIC_DATA = 'static_data'
const STORED_USER_PREFERENCES = 'user_preferences'
// const STORED_FILTERS = 'filters'
// const STORED_ADD_TOWING_ORDER = 'addTowingOrder'
// const STORED_EDIT_TOWING_ORDER = 'editTowingOrder'
// const STORED_TOWING_RECORD = 'towingRecord'
const STORED_TRACKED_RECORDS = 'tracked_records'
const STORED_IS_WEBVIEW = 'is_webview'
const STORED_BACKEND_NOTIFICATIONS = 'backend_notifications'

interface DecodedToken {
  user_id: number
  company_id: number
  exp: number
}

export function hasStoredLogin(): boolean {
  return !!window.localStorage.getItem(STORED_AUTHTOKEN_KEY)
}

export function getStoredLogin(): string | null {
  return window.localStorage.getItem(STORED_AUTHTOKEN_KEY)
}

export function getStoredLoginExpiration(): number | null {
  const exp = window.localStorage.getItem(STORED_AUTHTOKEN_EXP)
  if (exp) {
    return (exp as unknown) as number
  }
  return null
}

export function hasStoredCompanyId(): boolean {
  return !!window.localStorage.getItem(STORED_COMPANY_ID_KEY)
}

export function getStoredCompanyId(): string | null {
  return window.localStorage.getItem(STORED_COMPANY_ID_KEY)
}

export function getStoredUserId(): number | null {
  const stored = window.localStorage.getItem(STORED_CUSTOMER_ID_KEY)

  if (!stored) {
    return null
  }

  const result = parseInt(stored, 10)

  if (isNaN(result)) {
    return null
  }

  return result
}

export function storeLogin(token: string) {
  const tokenData = decode(token) as DecodedToken
  window.localStorage.setItem(STORED_AUTHTOKEN_KEY, token)
  window.localStorage.setItem(STORED_AUTHTOKEN_EXP, (tokenData.exp as unknown) as string)
  window.localStorage.setItem(STORED_CUSTOMER_ID_KEY, tokenData.user_id.toString())
  window.localStorage.setItem(STORED_COMPANY_ID_KEY, tokenData.company_id.toString())
}

export function storeLogout() {
  window.localStorage.clear()

  window.location.reload()
}

export const storeServiceWorkerEndpoint = (endpoint: string) => {
  window.localStorage.setItem(STORED_SERVICEWORKER_ENDPOINT, endpoint)
}

export const removeServiceWorkerEndpoint = () => {
  window.localStorage.removeItem(STORED_SERVICEWORKER_ENDPOINT)
}

export const getServiceWorkerEndpoint = () => window.localStorage.getItem(STORED_SERVICEWORKER_ENDPOINT)

export function hasStoredItemGroups(): boolean {
  return !!window.localStorage.getItem(STORED_ITEMGROUPS_KEY)
}

export function getStoredItemGroups(): Maybe<ItemGroup[]> {
  const itemGroups = window.localStorage.getItem(STORED_ITEMGROUPS_KEY)
  return itemGroups ? JSON.parse(itemGroups) : null
}

export function storeItemGroups(itemGroups: ItemGroup[]) {
  window.localStorage.setItem(STORED_ITEMGROUPS_KEY, JSON.stringify(itemGroups))
}

export function hasStoredItems(): boolean {
  return !!window.localStorage.getItem(STORED_ITEMS_KEY)
}

export function getStoredItems(): Maybe<Item[]> {
  const items = window.localStorage.getItem(STORED_ITEMS_KEY)
  return items ? JSON.parse(items) : null
}

export function storeItems(items: Item[]) {
  window.localStorage.setItem(STORED_ITEMS_KEY, JSON.stringify(items))
}

export function hasStoredSettings(): boolean {
  return !!window.localStorage.getItem(STORED_SETTINGS_KEY)
}

export function getStoredSettings(): Maybe<Settings> {
  const settings = window.localStorage.getItem(STORED_SETTINGS_KEY)
  return settings ? JSON.parse(settings) : null
}

export function storeSettings(settings: Settings) {
  window.localStorage.setItem(STORED_SETTINGS_KEY, JSON.stringify(settings))
}

export function hasStoredUserPreferences(): boolean {
  return !!window.localStorage.getItem(STORED_USER_PREFERENCES)
}

export function getStoredUserPreferences(): Maybe<Preferences> {
  const preferences = window.localStorage.getItem(STORED_USER_PREFERENCES)
  return preferences ? JSON.parse(preferences) : null
}

export function storeUserPreferences(preferences: Preferences) {
  window.localStorage.setItem(STORED_USER_PREFERENCES, JSON.stringify(preferences))
}

export function hasStoredStaticData(): boolean {
  return !!window.localStorage.getItem(STORED_STATIC_DATA)
}

export function getStoredStaticData(): Maybe<SosArcCodesFilters> {
  const staticData = window.localStorage.getItem(STORED_STATIC_DATA)
  return staticData ? JSON.parse(staticData) : null
}

export function storeStaticData(data: SosArcCodesFilters) {
  window.localStorage.setItem(STORED_STATIC_DATA, JSON.stringify(data))
}

export function storeTrackedRecords(records: Maybe<number[]>) {
  window.localStorage.setItem(STORED_TRACKED_RECORDS, JSON.stringify(records))
}

export function hasStoredTrackedRecords(): boolean {
  const records = getStoredTrackedRecords()
  return Array.isArray(records) && records.length > 0
}

export function getStoredTrackedRecords(): Maybe<number[]> {
  const trackedRecords = window.localStorage.getItem(STORED_TRACKED_RECORDS)
  return trackedRecords ? JSON.parse(trackedRecords) : null
}

export function isRunningInWebview(): boolean {
  const value = window.localStorage.getItem(STORED_IS_WEBVIEW)
  return value === null || value === '0' ? false : true
}

export function storeBackendNotifications(notifications: BackendNotification[]) {
  window.localStorage.setItem(STORED_BACKEND_NOTIFICATIONS, JSON.stringify(notifications))
}

export function getStoredBackendNotifications(): Maybe<BackendNotification[]> {
  const notifications = window.localStorage.getItem(STORED_BACKEND_NOTIFICATIONS)
  return notifications ? JSON.parse(notifications) : []
}

export function hasStoredBackendNotifications(): boolean {
  const notifications = window.localStorage.getItem(STORED_BACKEND_NOTIFICATIONS)
  return notifications ? true : false
}

export function removeStoredBackendNotifications() {
  window.localStorage.removeItem(STORED_BACKEND_NOTIFICATIONS)
  window.dispatchEvent(new Event('storage'))
}
