import { useSocket } from '../../util/useSocket'
import { hasStoredCompanyId, getStoredCompanyId, getStoredUserId } from '../../graphql-client/storedState'
import { useEffect } from 'react'
//const REACT_APP_SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL

export function useTowingJobsListChange(onChange: (message: string) => void) {
  const socket = useSocket()

  
  useEffect(()=> {
    
    if (!hasStoredCompanyId()) {
      console.error('No user id found for towing jobs watcher')
      return
    }
    const userId = getStoredUserId()
   
    const companyId = getStoredCompanyId()

    socket.on(`company-${companyId}.towing-order.changed`, onChange)
    userId && socket.on(`user-${userId}.orderCancelled`, onChange)
    userId && socket.on(`user-${userId}.recordCancelled`, onChange)

    return () => {
      console.log('removing event listener from socket')
      socket.removeEventListener(`company-${companyId}.towing-order.changed`, onChange)
      userId && socket.removeEventListener(`user-${userId}.orderCancelled`, onChange)
      userId && socket.removeEventListener(`user-${userId}.recordCancelled`, onChange)
    }
  }, [socket, onChange])

  
}
