import { Maybe, User } from '../../common/types'
import { Item, ItemGroup, Preferences, Settings } from '../Parameters/types'

export interface IState {
  itemGroups: Maybe<ItemGroup[]>
  items: Maybe<Item[]>
  settings: Maybe<Settings>
  openJobsCount: Maybe<number>
  currentUser: Maybe<User>
  offlineMode: boolean
  unSavedJobCount: number
  // arcCodes: Maybe<SosArcCodesFilters>
  userPreferences: Maybe<Preferences>
}

export enum ActionTypes {
  SET_ITEMGROUP = 'SET_ITEMGROUP',
  SET_ITEMS = 'SET_ITEMS',
  SET_SETTINGS = 'SET_SETTINGS',
  SET_JOBS_COUNT = 'SET_JOBS_COUNT',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_OFFLINE_MODE = 'SET_OFFLINE_MODE',
  SET_UN_SAVED_JOB_COUNT = 'SET_UN_SAVED_JOB_COUNT',
  // SET_ARC_CODES = 'SET_ARC_CODES',
  SET_USER_PREFERENCES = 'SET_USER_PREFERENCES',
}

export type Action =
  | SetItemGroupAction
  | SetItemAction
  | SetSettingsAction
  | SetOpenJobsCountAction
  | SetCurrentUserAction
  | SetOfflineModeAction
  | SetUnSavedJobCount
  | SetUserPreferencesAction

export interface SetItemGroupAction {
  type: ActionTypes.SET_ITEMGROUP
  value: ItemGroup[]
}

export interface SetCurrentUserAction {
  type: ActionTypes.SET_CURRENT_USER
  value: User
}

export interface SetItemAction {
  type: ActionTypes.SET_ITEMS
  value: Item[]
}

export interface SetSettingsAction {
  type: ActionTypes.SET_SETTINGS
  value: Settings
}

export interface SetOpenJobsCountAction {
  type: ActionTypes.SET_JOBS_COUNT
  value: number | null
}

export interface SetOfflineModeAction {
  type: ActionTypes.SET_OFFLINE_MODE
  value: boolean
}

export interface SetUnSavedJobCount {
  type: ActionTypes.SET_UN_SAVED_JOB_COUNT
  value: number
}

// export interface SetUserPreferencesAction {
//   type: ActionTypes.SET_ARC_CODES
//   value: Maybe<SosArcCodesFilters>
// }

export interface SetUserPreferencesAction {
  type: ActionTypes.SET_USER_PREFERENCES
  value: Maybe<Preferences>
}

export type IContext = IStateContext & IDispatchContext

export interface IStateContext {
  state: IState
}

export interface IDispatchContext {
  dispatch: React.Dispatch<Action>
}
