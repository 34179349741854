import React, { useReducer } from 'react'

import { GeolocationWatcher } from '../../components/TrackedJobs/GeolocationWatcher'
import { isRunningInWebview } from '../../graphql-client/storedState'
import { OfflineMode } from './OfflineMode'
import { OpenJobCountWatcher } from './OpenJobCountWatcher'
import { reducer } from './reducers'
import { IDispatchContext, IState, IStateContext } from './types'

const initialState: IState = {
  itemGroups: null,
  items: null,
  settings: null,
  openJobsCount: null,
  currentUser: null,
  offlineMode: false,
  unSavedJobCount: 0,
  userPreferences: null,
}

export const StateStore = React.createContext({} as IStateContext)
export const DispatchStore = React.createContext({} as IDispatchContext)

export const StoreProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const isWebview = isRunningInWebview()

  return (
    <StateStore.Provider value={{ state }}>
      <DispatchStore.Provider value={{ dispatch }}>
        {state.currentUser && <OpenJobCountWatcher />}
        {state.currentUser && !isWebview && <GeolocationWatcher />}
        {state.currentUser && <OfflineMode />}
        {children}
      </DispatchStore.Provider>
    </StateStore.Provider>
  )
}
