import { Action, ActionTypes, IState } from './types'

export function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case ActionTypes.SET_ITEMGROUP:
      return { ...state, itemGroups: action.value }
    case ActionTypes.SET_ITEMS:
      return { ...state, items: action.value }
    case ActionTypes.SET_SETTINGS:
      return { ...state, settings: action.value }
    case ActionTypes.SET_JOBS_COUNT:
      return { ...state, openJobsCount: action.value }
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.value,
      }
    case ActionTypes.SET_OFFLINE_MODE:
      return { ...state, offlineMode: action.value }
    case ActionTypes.SET_UN_SAVED_JOB_COUNT:
      return { ...state, unSavedJobCount: action.value }
    // case ActionTypes.SET_ARC_CODES:
    //   return { ...state, arcCodes: action.value }
    case ActionTypes.SET_USER_PREFERENCES:
      return { ...state, userPreferences: action.value }
    default:
      return state
  }
}
