import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { CountCircle, MenuItem } from '../layout'
import { RouterNavLink } from '../RouterNavLink'
import { Maybe } from '../../common/types'

type IconCategory = 'orders' | 'search' | 'shifts' | 'settings'
type IconType = 'work-wrench' | 'search' | 'clock' | 'settings'

interface MenuLinkConfig {
  icon: IconType
}

const menuIcon: Record<IconCategory, MenuLinkConfig> = {
  orders: { icon: 'work-wrench' },
  search: { icon: 'search' },
  shifts: { icon: 'clock' },
  settings: { icon: 'settings' },
}

interface Props {
  category: keyof typeof menuIcon
  to: string
  label: string
  exact?: boolean
  count?: Maybe<number>
}

export const MenuLink: React.FunctionComponent<Props> = ({ category, count, /*label,*/ to, exact = false }) => {
  const { icon } = menuIcon[category]
  return (
    <MenuItem>
      {!!count && (
        <CountCircle smallFont={count > 9 ? true : false}>{count}</CountCircle>
      )}
      <RouterNavLink to={to} exact={exact}>
        <IconWrapper>
          <Icon color="grey500" icon={icon} />
        </IconWrapper>
        {/*<MenuItemLabel color={'grey500'}>{label}</MenuItemLabel>*/}
      </RouterNavLink>
    </MenuItem>
  )
}

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  color: ${props => props.theme.colors.black};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`

export const MenuItemLabel = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
`
