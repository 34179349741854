import React, { useCallback, useEffect, useState } from 'react'
import { getStoredCompanyId, getStoredUserId } from '../../graphql-client/storedState'
import { useSocket } from '../../util/useSocket'
import { Button } from '../Button'
import { useHistoryPush } from '../router'
import styled from 'styled-components'
import { ModalContainer, ModalHeader, ModalText } from '../layout'
import { ButtonContainer } from '../../containers/TowingRecordPage/components/routes/subforms/styled'

interface MessageData {
    header: string
    msg: string
    timeStamp: string
    towingDate: string
    towingTime: string
    vehicleMakeAndModel: string
    from: string
    to: string
    orderId?: number
    recordId?: number
}

export const SocketNotificationModal: React.FunctionComponent<any> = () => {
  const socket = useSocket()
  const userId = getStoredUserId()
  const companyId = getStoredCompanyId()
  const [activePushMessage, setActivePushMessage] = useState<boolean>(true);
  const [messages, setMessages] = useState<MessageData[]>([]);


  useEffect(() => {
    if(!userId || !companyId){
      socket.removeAllListeners()
      socket.close()
      console.log('closing socket connection')
      return
    }
    const callback = (message: string)=>{
      const event: MessageData = JSON.parse(message)
      console.log('this is the event:', event)

      setActivePushMessage(true)
      setMessages(messages => [event, ...messages])
    }

    socket.on(`user-${userId}.orderCancelled`, callback)
    socket.on(`user-${userId}.recordCancelled`, callback)
    
    return ()=>{
      socket.removeListener(`user-${userId}.orderCancelled`, callback)
      socket.removeListener(`user-${userId}.recordCancelled`, callback)
    }
  },[userId, companyId, socket, setMessages, setActivePushMessage])

  useEffect(()=>{
    if(messages.length < 1){
      setActivePushMessage(false)
    }
  },[setActivePushMessage, messages])

  const historyPush = useHistoryPush()
  const onConfirm = useCallback((index: number)=>{
    const current = messages[index]
    if(current.recordId){
      setActivePushMessage(false)
      historyPush(`/towing-record/${current.recordId}`)
      setMessages(messages.filter(message => message.orderId !== current.orderId))
    }
    if(current.orderId){
      setMessages(messages.filter(message => message.orderId !== current.orderId))
    }
  }, [messages, historyPush])

  const onClose = useCallback(()=>{
    setMessages([]);
    setActivePushMessage(false);

  },[])
  return <>{activePushMessage && 
    <ModalContainer style={{background: 'white', paddingBottom: '80px'}}>
      <ModalHeader style={{paddingLeft: '2rem'}}>
        <ModalText>Muutoksia tilauksiin</ModalText>
      </ModalHeader>
    <SocketMessageContainer>
      {
        messages.map((message, index) => {
          return (
            <SocketMessageListItem key={index}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                  <MessageContainer>
                  <MessageItemWide><MessageLabel>{message.timeStamp + ' - ' + message.header}</MessageLabel></MessageItemWide>
                  <MessageItemWide><MessageContent>{message.msg}</MessageContent></MessageItemWide>
                  <MessageItem>{'Tilauksen ajankohta: '}</MessageItem>
                  <MessageData>{message.towingDate + ' ' + message.towingTime}</MessageData>
                  <MessageItem>{'Merkki ja malli: '}</MessageItem>
                  <MessageData>{message.vehicleMakeAndModel}</MessageData>
                  <MessageItem>{'Mistä: '}</MessageItem>
                  <MessageData>{message.from}</MessageData>
                  <MessageItem>{'Mihin: '}</MessageItem>
                  <MessageData>{message.to}</MessageData>
                  {message.recordId && <MessageItemWide><MessageWarning>Huom! Työ lopetettava työnäkymässä</MessageWarning></MessageItemWide>}
                  {message.recordId && (<MessageButtonItem><Button category={'on'} maxWidth='100%' size='s' onClick={() => {onConfirm(index)}}>{'Siirry työnäkymään'}</Button></MessageButtonItem>)}
                </MessageContainer>
              </div>
            </SocketMessageListItem>
          )
        })
      }
    </SocketMessageContainer>
    <ButtonContainer floatAtBottomNoMenu>
          <Button category='save' size='m' maxWidth='100%' onClick={onClose}>Sulje</Button> 
    </ButtonContainer>
  </ModalContainer>
  }</>
}


const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 0rem;
  grid-column-gap: 3rem;
  padding: 0.5rem 0rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: auto;
}
`
const MessageItem = styled.div`
  padding-bottom: 0.2rem
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-weight: bold;
  }
`
const MessageData = styled.div`
  padding-bottom: 0.2rem
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding-bottom: 0.5rem
  }
`
const MessageContent = styled.div`
  padding: 0.2rem 0 1rem 0;
`

const MessageItemWide = styled.div`
  grid-column: span 2;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
}
`

const MessageButtonItem = styled.div`
  grid-column: span 2;
  padding-top: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
}
`

const MessageLabel = styled.div`
  font-size: large;
  font-weight: bold;
`

const MessageWarning = styled.div`
  font-weight: bold;
  padding: 0.8rem 0 0 0;
`

export const SocketMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SocketMessageListItem = styled.div`
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; 
  border-bottom: solid 3px #d6d6d6; 
  padding: 1rem 2rem;
`
