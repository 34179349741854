import { getIn } from 'formik'
import React, { useCallback, useState } from 'react'

import { FormSubGroup, FormSubGroupHeading, ImageWrapper, Section, SectionColumn } from '../../../components/layout'
import { SelectInput, TextAreaInput, TextInput } from '../../../components/layout/form/input'
import {
  DateSectionColumn,
  HalfDateInput,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import lahitapiola from '../../../images/lahitapiola.png'
import { Option } from '../../../styled/styled'
import { sosCauseFilters, sosVariables } from '../../SosSubForm/types'
import { LahitapiolaFormSubGroupHeader, LahitapiolaFormSubGroupWrapper } from '../components'

interface FormContentsProps {
  getValue: any
  setValue: any
  isTowingRecord?: boolean
}

export const LahitapiolaSosOrderDetailsForm: React.FunctionComponent<FormContentsProps> = ({
  getValue,
  setValue,
  isTowingRecord,
}) => {
  const deliverable = getValue('sosServiceOrder.deliverable')
  const [causeOptions, setCauseOptions] = useState<Option[]>(() => {
    if (!deliverable) {
      return []
    }

    const causes = getIn(sosCauseFilters, deliverable as string)

    if (!causes) {
      return []
    }

    const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

    return options
  })

  const onDeliverableChange = useCallback(
    (deliverable: string | number | undefined) => {
      if (!deliverable) {
        setCauseOptions([])
      }

      const causes = getIn(sosCauseFilters, deliverable as string)

      if (!causes) {
        setCauseOptions([])
      }

      const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

      setCauseOptions(options)
      setValue('sosServiceOrder.cause', null)
    },
    [setValue]
  )

  return (
    <LahitapiolaFormSubGroupWrapper>
      <LahitapiolaFormSubGroupHeader>
        <FormSubGroupHeading>
          <ImageWrapper>
            <img src={lahitapiola} alt="Lähitapiola" width={`95px`} />
          </ImageWrapper>
        </FormSubGroupHeading>
      </LahitapiolaFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <HalfDateInput label={'Päivämäärä'} name="jobDetails.towingDate" required disabled={isTowingRecord} />
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" disabled={isTowingRecord} />
          </DateSectionColumn>
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Toimenpide"
              name="sosServiceOrder.deliverable"
              options={sosVariables.deliverable}
              onChange={value => onDeliverableChange(value)}
              disabled={isTowingRecord}
              required
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vika"
              name="sosServiceOrder.cause"
              options={causeOptions}
              disabled={isTowingRecord}
              required
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'Viite'} name="automobileAndTouringClubFinland.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Laskutus" name="automobileAndTouringClubFinland.billingInfo" disabled />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Lisäselvitykset'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        {/* <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="Tilannearvio" name="automobileAndTouringClubFinland.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section> */}
      </FormSubGroup>
    </LahitapiolaFormSubGroupWrapper>
  )
}
