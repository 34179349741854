import styled, { css } from 'styled-components'
interface TextProps {
  color?: string
  size?: string
  fontSize?: string
  fontWeight?: string
  margin?: string
}

interface Props {
  color?: string
}

export const HeadingCommonStyles = css<Props>`
  color: ${props => props.theme.colors.grey600};
  color: ${props => props.color && props.theme.colors[props.color]};
`

export const Heading1 = styled.h1`
  ${HeadingCommonStyles}
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 2.1rem;
  }
`

export const Heading2 = styled.h2`
  ${HeadingCommonStyles}
  font-size: 2.0rem;
  font-weight: 700;
  margin: 0 0;
  padding: 0;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 1.8rem;
  }
`

export const Heading3 = styled.h3<TextProps>`
  ${HeadingCommonStyles}
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  ${props =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `}
  padding: 0;
`

export const Heading4 = styled.h4`
  ${HeadingCommonStyles}
  font-size: 1rem;
  font-weight: 700;
  margin: 0 0 0.3em 0;
  padding: 0;
`

export const Text = styled.p<TextProps>`
  margin: 0;
  padding: 0;
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `}
  ${props =>
    props.size === 'big' &&
    css`
      font-size: 1.5em;
      line-height: 2rem;
    `}
    ${props =>
      props.size === 'medium' &&
      css`
        font-size: 1.25rem;
      `}
        @media (max-width: ${props => props.theme.screenSize.mobile}) {
          ${props =>
            props.size === 'small' &&
            css`
              font-size: 0.8rem;
            `}
        }
    ${props =>
      props.fontWeight &&
      css`
        font-weight: ${props.fontWeight};
      `}
      ${props =>
        props.margin &&
        css`
          margin: ${props.margin};
        `}
        ${props =>
          props.size === 'error' &&
          css`
            font-size: 1rem;
          `}
`
