import { getIn } from 'formik'
import React, { RefObject, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  DateSectionColumn,
  HalfDateInput,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import sos from '../../../images/sos.gif'
import { Option } from '../../../styled/styled'
import {
  Column,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  ImageWrapper,
  Row,
  Section,
  SectionColumn,
} from '../../layout'
import { SelectInput, TextAreaInput, TextInput } from '../../layout/form/input'
import { sosCauseFilters, sosVariables } from '../types'

interface SosServiceOrderDetailsFormProps {
  getValue: any
  setValue: any
  sosRef?: RefObject<any>
  scrollToThis?: boolean
}

export const SosServiceOrderDetailsForm: React.FunctionComponent<SosServiceOrderDetailsFormProps> = ({
  getValue,
  setValue,
  sosRef,
  scrollToThis,
}) => {
  const [shouldScroll, setShouldScroll] = useState<boolean>(scrollToThis || false)
  const handleScroll = useCallback(() => {
    if (sosRef && sosRef.current) {
      sosRef.current.scrollIntoView()
    }

    setShouldScroll(false)
  }, [sosRef, setShouldScroll])

  useEffect(() => {
    if (shouldScroll) {
      handleScroll()
    }
  }, [shouldScroll, handleScroll])
  const deliverable = getValue('sosServiceOrder.deliverable')
  const [disableDeliverable, setDisableDeliverable] = useState<boolean>(() => {
    if ((deliverable as string) === 'Empty ride') {
      return true
    }

    return false
  })
  const [causeOptions, setCauseOptions] = useState<Option[]>(() => {
    if (!deliverable) {
      return []
    }

    const causes = getIn(sosCauseFilters, deliverable as string)

    if (!causes) {
      return []
    }

    const options = sosVariables.cause.filter((cause) => causes.includes(cause.value))

    return options
  })

  const onDeliverableChange = useCallback(
    (deliverable: string | number | undefined) => {
      if (!deliverable) {
        setCauseOptions([])
      }

      const causes = getIn(sosCauseFilters, deliverable as string)

      if (!causes) {
        setCauseOptions([])
      }

      const options = sosVariables.cause.filter((cause) => causes.includes(cause.value))

      setCauseOptions(options)
      setValue('sosServiceOrder.cause', null)
    },
    [setValue]
  )

  const deliverables = sosVariables.deliverable

  useEffect(() => {
    if ((deliverable as string) !== 'Empty ride' && deliverables.length === 9) {
      deliverables.pop()
    }

    if ((deliverable as string) === 'Empty ride') {
      setDisableDeliverable(true)
    }
  }, [deliverable, deliverables])
  return (
    <SosFormSubGroupWrapper>
      <SosFormSubGroupHeader>
        <Row>
          <Column>
            <FormSubGroupHeading>
              <ImageWrapper>
                <img src={sos} alt="SOS" width={`95px`} />
              </ImageWrapper>
            </FormSubGroupHeading>
          </Column>
          {/* <Column justify="flex-end">
            <RadioButtonGroup
              label={''}
              name="jobDetails.towingDateType"
              options={translatedOrderScheduleOptions}
            ></RadioButtonGroup>
          </Column> */}
        </Row>
      </SosFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <HalfDateInput label={'Työn pvm'} name="jobDetails.towingDate" required />
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" />
          </DateSectionColumn>
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'SOS Viite'} name="sosServiceOrder.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vakuutusyhtiö"
              name="sosServiceOrder.insuranceCompany"
              options={sosVariables.insuranceCompany}
              required
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Arvioitu toimenpide"
              name="sosServiceOrder.deliverable"
              options={deliverables}
              disabled={disableDeliverable}
              onChange={(value) => onDeliverableChange(value)}
              required
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Arvioitu vika"
              name="sosServiceOrder.cause"
              options={causeOptions}
              disabled={disableDeliverable}
              required
            />
          </SectionColumn>
        </Section>

        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Työn lisätiedot'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot (sisäiset)'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="SOS Merkinnät" name="sosServiceOrder.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section>
      </FormSubGroup>
    </SosFormSubGroupWrapper>
  )
}

export const SosFormSubGroupHeader = styled(FormSubGroupHeader)`
  background-color: rgba(221, 41, 30, 0.1);
`
export const SosFormSubGroupWrapper = styled(FormSubGroupWrapper)`
  border-color: rgba(221, 41, 30, 0.5) !important;
`
