export const persistentSave = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data))
}

export const persistentDelete = (key: string) => {
  window.localStorage.removeItem(key)
}

export type GenericStorageRetrieveFn<T> = (key: string) => T

export function persistentGet<T>(key: string): T {
  const getResult = window.localStorage.getItem(key)
  return getResult ? JSON.parse(getResult) : null
}
