import React from 'react'
import Linkify from 'react-linkify'

import { TypeIdentifier } from '../../../common/types'
import { SectionTextColumn, Text } from '../../../components/layout'
import { TowingOrder } from '../../../containers/TowingOrderPage/ViewTowingOrder/types'
import { SummaryTowingRecord } from '../../../containers/TowingRecordPage/components/SummaryBody/types'
import { TowingRecord } from '../../../containers/TowingRecordPage/ViewTowingRecord/types'
import { toMultilineText } from '../../../util/common'
import { getComponentLabel, getDescriptionLabel } from '../../ALSubForm/options'
import { SectionBody, SectionHeader } from '../../layout/section'
import { translateCause, translateDeliverable } from '../../SosSubForm/util'

type TowingObject = TowingOrder | TowingRecord | SummaryTowingRecord

export const buildJobDetails = (towingObject: TowingObject) => {
  if (towingObject.typeIdentifier === TypeIdentifier.lahitapiola) {
    return buildLahitapiolaDetails(towingObject)
  }

  if (towingObject.typeIdentifier === TypeIdentifier.autoliitto) {
    return buildAutoliittoDetails(towingObject)
  }

  if (towingObject.typeIdentifier === TypeIdentifier.sos) {
    return buildSosDetails(towingObject)
  }

  return buildDetails(towingObject)
}

const buildLahitapiolaDetails = (towingObject: TowingObject) => {
  return (
    <>
      {towingObject.automobileAndTouringClubFinland && (
        <>
          {!towingObject.sosServiceOrder ? (
            <SectionTextColumn width="100%">
              <SectionHeader>
                <Text size="medium">Vika</Text>
              </SectionHeader>
              <SectionBody>
                <Text size="medium">{towingObject.jobDetails.towingReason}</Text>
              </SectionBody>
            </SectionTextColumn>
          ) : (
            <SectionTextColumn width="100%">
              <SectionHeader>
                <Text size="medium">Vika ja toimenpide</Text>
              </SectionHeader>
              <SectionBody>
                <Text size="medium">
                  {translateCause(towingObject.sosServiceOrder.cause || towingObject.sosServiceOrder.estimatedCause)}
                </Text>
                <Text size="medium">
                  {translateDeliverable(
                    towingObject.sosServiceOrder.serviceType || towingObject.sosServiceOrder.estimatedServiceType
                  )}
                </Text>
              </SectionBody>
            </SectionTextColumn>
          )}
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Viite</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.jobDetails.orderReference}</Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Tilannearvio</Text>
            </SectionHeader>
            <SectionBody>
              <Linkify>{toMultilineText(towingObject.automobileAndTouringClubFinland.situationDescription)}</Linkify>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Laskutus</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.automobileAndTouringClubFinland.billingInfo}</Text>
            </SectionBody>
          </SectionTextColumn>
        </>
      )}
    </>
  )
}

const buildAutoliittoDetails = (towingObject: TowingObject) => {
  return (
    <>
      {towingObject.automobileAndTouringClubFinland && (
        <>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">ARC-vikakoodi</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">
                {getComponentLabel(towingObject.automobileAndTouringClubFinland.arcCodes.component.code)}
              </Text>
              <Text size="medium">
                {getDescriptionLabel(towingObject.automobileAndTouringClubFinland.arcCodes.description.code)}
              </Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Viite</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.automobileAndTouringClubFinland.caseNumber}</Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Autoliiton tilannearvio</Text>
            </SectionHeader>
            <SectionBody>
              <Linkify>{toMultilineText(towingObject.automobileAndTouringClubFinland.situationDescription)}</Linkify>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Palvelukanava ja laskutus</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.automobileAndTouringClubFinland.serviceChannel}</Text>
              <Text size="medium">{towingObject.automobileAndTouringClubFinland.billingInfo}</Text>
            </SectionBody>
          </SectionTextColumn>
        </>
      )}
    </>
  )
}

const buildSosDetails = (towingObject: TowingObject) => {
  return (
    <>
      {towingObject.sosServiceOrder && (
        <>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Vika ja toimenpide</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">
                {translateCause(towingObject.sosServiceOrder.cause || towingObject.sosServiceOrder.estimatedCause)}
              </Text>
              <Text size="medium">
                {translateDeliverable(
                  towingObject.sosServiceOrder.deliverable || towingObject.sosServiceOrder.estimatedServiceType
                )}
              </Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">SOS Viite</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.sosServiceOrder.caseNumber}</Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">SOS Merkinnät</Text>
            </SectionHeader>
            <SectionBody>{toMultilineText(towingObject.sosServiceOrder.situationDescription)}</SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Konsepti</Text>
            </SectionHeader>
            <SectionBody>
              <Text size="medium">{towingObject.sosServiceOrder.agreementName}</Text>
            </SectionBody>
          </SectionTextColumn>
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Työn tiedot</Text>
            </SectionHeader>
            <SectionBody>
              <Linkify>{toMultilineText(towingObject.jobDetails.additionalInfo)}</Linkify>
            </SectionBody>
          </SectionTextColumn>
        </>
      )}
    </>
  )
}

const buildDetails = (towingObject: TowingObject) => {
  return (
    <>
      <SectionTextColumn width="100%">
        <SectionHeader>
          <Text size="medium">Vika</Text>
        </SectionHeader>
        <SectionBody>
          <Text size="medium">{towingObject.jobDetails.towingReason}</Text>
        </SectionBody>
      </SectionTextColumn>
      <SectionTextColumn width="100%">
        <SectionHeader>
          <Text size="medium">Viite</Text>
        </SectionHeader>
        <SectionBody>
          <Text size="medium">{towingObject.jobDetails.orderReference}</Text>
        </SectionBody>
      </SectionTextColumn>
    </>
  )
}
